<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              v-if="$route.name == 'OrgRoleList'"
              :to="{
                name: 'SystemSettingsHome',
              }"
            >
              全系統管理
            </b-breadcrumb-item>
            <b-breadcrumb-item
              v-if="$route.name == 'OrgRoleList'"
              :to="{
                name: 'OrganizationList',
              }"
            >
              組織管理
            </b-breadcrumb-item>

            <b-breadcrumb-item active v-if="$route.name == 'RoleList'">
              組織資料中心
            </b-breadcrumb-item>
            <b-breadcrumb-item active>權限管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-6 mb-2 font-weight-bold">
          {{ `【${organizationName}】權限管理` }}
        </h4>
        <div
          class="
            col-12 col-xl-6
            d-flex
            align-items-start align-items-xl-center
            mb-2
          "
        >
          <b-button
            v-if="checkPermission([consts.ROLE_MODIFY])"
            class="flex-shrink-0 mb-2 mr-2"
            variant="primary"
            :to="getCreateLink()"
          >
            <i class="fa fa-plus"></i>新增角色
          </b-button>

          <b-input-group>
            <b-form-input v-model="query.keyword" placeholder="搜尋"></b-form-input>
            <b-input-group-append>
              <b-button @click="handlePageResetWithoutReload"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="roles"
                :fields="fields"
              >
                <template #cell(actions)="data">
                  <b-button
                    variant="inverse-dark"
                    :to="getStaffLink(data.item)"
                  >
                    <span class="mdi mdi-account-multiple mr-1"></span>相關帳號
                  </b-button>
                  <b-button
                    variant="inverse-info"
                    :to="getPermissionEditLink(data.item)"
                  >
                    <span class="mdi mdi-account-key mr-1"></span>編輯權限
                  </b-button>
                  <b-button
                    variant="inverse-secondary"
                    @click="showCopyPermission(data.item)"
                  >
                    <span class="mdi mdi-account-switch mr-1"></span>複製權限
                  </b-button>
                  <b-button
                    variant="inverse-primary"
                    :to="getShowLink(data.item)"
                  >
                    <span class="mdi mdi-eye"></span> 查看
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.ROLE_MODIFY])"
                    variant="inverse-warning"
                    :to="getEditLink(data.item)"
                  >
                    <span class="mdi mdi-lead-pencil"></span> 編輯
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.ROLE_MODIFY])"
                    variant="inverse-danger"
                    @click="deleteRole(data.item)"
                  >
                    <span class="mdi mdi-delete"></span> 刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-card-text>
    </b-card>

    <b-modal id="modal-copy-permission" title="複製權限" centered>
      <h4>{{ `複製 ${selectedRole.name} 的權限` }}</h4>
      <p>
        {{ `請選取目標角色，會將 ${selectedRole.name} 的權限帶入` }}
      </p>

      <b-form-group>
        <b-form-select
          v-model="targetRole"
          :options="optionRoles"
        ></b-form-select>
      </b-form-group>

      <template #modal-footer>
        <b-button
          size="sm"
          variant="success"
          @click="copyPermission('remove_and_copy')"
        >
          移除現有權限，用新權限取代
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="copyPermission('reserve_and_copy')"
        >
          繼承現有權限，並加上新權限
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import roleApi from "@/apis/role";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import { updateQueryFromRoute, updateUrlWithoutReload } from "@/utils/updateUrl";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { paginationMixin } from "@/mixins/pagination";

export default {
  components: { CustomPagination },
  mixins: [ paginationMixin ],
  data() {
    return {
      consts: consts,
      totalRows: 0,
      showLoading: true,
      roles: [],
      optionRoles: [],
      fields: [
        {
          key: "name",
          label: "角色名稱",
        },
        {
          key: "code",
          label: "角色代碼",
        },
        {
          key: "level",
          label: "Level",
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      query: {
        page: 1,
        per_page: 10,
        keyword: "",
      },
      initialized: false,
      organizationName: "",
      targetRole: null,
      selectedRole: { id: null, name: "" },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters("route", ["routeQuery"]),
    orgId() {
      return this.$route.params.orgId || this.organization.id;
    },
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrlWithoutReload(this.query, this.$store, this.$router);
      },
    },
    routeQuery() {
      if (!this.initialized) return;
      this.getRoles(this.query.page);
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getRoles();
      await this.getRoleOptions();
      this.initialized = true;
    },
    getCreateLink() {
      if (this.$route.name == "OrgRoleList") {
        return { name: "OrgRoleCreate", params: { orgId: this.orgId } };
      }
      return { name: "RoleCreate" };
    },
    getPermissionEditLink(role) {
      if (this.$route.name == "OrgRoleList") {
        return {
          name: "OrgRolePermissionEdit",
          params: { orgId: this.orgId, role_id: role.id },
        };
      }
      return { name: "RolePermissionEdit", params: { role_id: role.id } };
    },
    getStaffLink(role) {
      if (this.$route.name == "OrgRoleList") {
        return {
          name: "OrgRoleStaffList",
          params: { orgId: this.orgId, role_id: role.id },
        };
      }
      return { name: "RoleStaffList", params: { role_id: role.id } };
    },
    getShowLink(role) {
      if (this.$route.name == "OrgRoleList") {
        return {
          name: "OrgRoleShow",
          params: { orgId: this.orgId, role_id: role.id },
        };
      }
      return { name: "RoleShow", params: { role_id: role.id } };
    },
    getEditLink(role) {
      if (this.$route.name == "OrgRoleList") {
        return {
          name: "OrgRoleEdit",
          params: { orgId: this.orgId, role_id: role.id },
        };
      }
      return { name: "RoleEdit", params: { role_id: role.id } };
    },
    showCopyPermission(role) {
      this.selectedRole = role;
      this.targetRole = null;
      this.$bvModal.show("modal-copy-permission");
    },
    async getRoleOptions() {
      const { data } = await roleApi.getRoles(this.orgId, {is_all : 1});
      this.optionRoles = [
        { value: null, text: "請選擇" },
        ...data.data.map((role) => {
          return {
            value: role,
            text: role.name,
          };
        }),
      ];
    },
    async getRoles() {
      this.showLoading = true;
      try {
        let params = { ...this.query };
        const { data } = await roleApi.getRoles(this.orgId, params);
        this.roles = data.data;
        this.totalRows = data.meta.total;
        this.organizationName = data.meta.organization_name;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "取得角色資料錯誤",
          });
        }
      }
      this.showLoading = false;
    },
    async deleteRole(role) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>角色名稱：${role.name}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              let response = await roleApi.removeRole(this.orgId, role);
              if (response.status === 200) {
                this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "成功刪除 " + role.name,
                });
                this.getRoles();
              }
            } catch (error) {
              let message = error.response.data.message || "刪除失敗";
              this.$swal.fire({
                title: "新增失敗",
                type: "error",
                text: message,
              });
            }
          }
        });
    },
    copyPermission(copyType) {
      let route = this.getPermissionEditLink(this.targetRole);
      route.query = {
        copy_from: this.selectedRole.id,
        copy_type: copyType,
      };
      this.$router.push(route);
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}
</style>
